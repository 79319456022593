// 监控
<template>
  <!-- style="min-width: 1381px" -->
  <div style="min-width: 1350px">
    <el-row :gutter="15" style="min-height:calc(100vh - 130px)" class="flex">
      <el-col :span="17" class="pr">
        <el-card style="height:100%; border-color: #edeef0">
          <div class="box-card flex">
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">专利号</span>
              <el-input v-model="patent_code"></el-input>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">缴费截止日</span>
              <el-date-picker value-format="yyyy-M-d" v-model="pay_time" type="date"></el-date-picker>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">申请日</span>
              <el-date-picker value-format="yyyy-M-d" v-model="patent_date_app" type="date"></el-date-picker>
            </div>

            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">业务类型</span>
              <el-select class="w-200" v-model="service_id" placeholder="请选择">
                <el-option label="外观" value="1"></el-option>
                <el-option label="实用" value="2"></el-option>
                <el-option label="发明" value="3"></el-option>
              </el-select>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">监控</span>
              <el-select class="w-200" v-model="is_monitoring" placeholder="请选择">
                <el-option label="所有案件" value="*"></el-option>
                <el-option label="监控开启" value="1"></el-option>
                <el-option label="未监控" value="2"></el-option>
              </el-select>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">临近缴费日</span>
              <el-select class="w-200" v-model="close_to_pay" placeholder="请选择">
                <el-option label="今天" value="1"></el-option>
                <el-option label="近七天" value="7"></el-option>
                <el-option label="近一个月" value="30"></el-option>
                <el-option label="近二个月" value="60"></el-option>
                <el-option label="近三个月" value="90"></el-option>
                <el-option label="近五个月" value="150"></el-option>
              </el-select>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">本所/外所</span>
              <el-select class="w-200" v-model="is_trusteeship">
                <el-option label="本所" value="0"></el-option>
                <el-option label="外所" value="1"></el-option>
              </el-select>
            </div>
            <div class="mt-30 mr-30">
              <span class="mr-10 f14 we">代理所名称</span>
              <el-input  v-model="agency_office_name"></el-input>
            </div>

            <el-button
              @click="cx"
              class="f14 mt-30 bg-b"
              style="
                width: 80px;
                height: 32px;
                padding: 8px 10px;
                margin-left: 94px;
              "
            >查询</el-button>
          </div>
          <div class="flex-jb-al">
            <div class="flex-al">
              <el-button class="mr-30 bg-b" @click="$router.push('/import')">监控案件手动导入</el-button>
              <el-upload
                class="mr-20"
                :headers="myHeaders"
                action="/client/patent/excelToLead"
                accept=".xlsx"
                :show-file-list="false"
                :on-success="success"
                :on-error="error"
                name="file"
              >
                <el-button class="bg-b">监控案件Excel批量导入</el-button>
              </el-upload>
              <a class="n-w f14" :href="patent_template">批量导入模板下载</a>
            </div>
            <div class="flex-al">
              <div class="ml-20 n-w">
                已监控
                <span class="red">{{infor.already_monitoring}}</span>条
              </div>
              <div class="ml-20 n-w">
                还剩
                <span class="red">{{infor.monitor_num}}</span>条
              </div>
              <div class="ml-20 n-w">
                剩余监控
                <span class="red">{{infor.monitor_end_days}}</span>天
              </div>
              <span
                @click="cz"
                class="bg-b ml-20 cur t-c"
                style="width: 104px;height: 32px;line-height:32px;border-radius: 4px;margin-right:17px"
              >充值</span>
            </div>
          </div>
          <!-- max-height="544" -->
          <el-table
            max-height="544"
            :data="tableData"
            border
            :header-cell-style="{
              color: '#222530',
              fontSize: '14px',
              backgroundColor: '#eaf1ff',
              borderColor: '#ccc',
            }"
            style="width:100%; font-size: 10px; margin-top: 30px"
          >
            <el-table-column min-width="120" label="到期时间" align="center" class="pr">
              <template slot-scope="scope">
                <div
                  class="pa f14 t-c"
                  style="
                    border: 1px solid;
                    width: 27px;
                    top: 0;
                    left: 0;
                    color: #fff;
                    bottom: -1px;
                  "
                  :style="{backgroundColor:scope.row.is_pay_fees==-1?'#bbb':scope.row.is_pay_fees==0?'#2962FF':scope.row.is_pay_fees==1?'#F13D3D':''}"
                >
                  <!-- -1已超时，0，已缴费 ，1待缴费 -->
                  {{scope.row.is_pay_fees==-1?'已超时':'未超时'}}
                </div>
                <div>{{ scope.row.annual_fee_by_time||'' }}</div>
              </template>
            </el-table-column>
            <el-table-column min-width="230" label="专利信息" header-align="center">
              <template slot-scope="scope">
                <div>
                  <span class="type-tag mr-10">{{ scope.row.configService.service_name }}</span>
                  <span>{{ scope.row.patent_code }}</span>
                </div>
                <div class="mt-5" style="line-height: 18px">{{ scope.row.name }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column min-width="120" prop="is_overdue_fine" label="年费状态" align="center">
              <template slot-scope="scope">
                <div>
                  {{
                  scope.row.is_overdue_fine == -1? "无法缴费": scope.row.is_overdue_fine == 1? "待缴滞纳金": "不需要缴滞纳金"
                  }}
                </div>
              </template>
            </el-table-column> -->
             <el-table-column min-width="120"  label="代理所" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.agency_office_name">{{scope.row.agency_office_name}}</div>
                <div v-else> <el-tag effect="plain" class="mr-10" @click="tapAgency(scope.row)">添加</el-tag></div>
              </template>
            </el-table-column>
            <el-table-column min-width="120" prop="patent_date_app" label="申请日" align="center"></el-table-column>
            <el-table-column min-width="120" label="年费监控" align="center">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.annualFeeControl.status"
                  @change="change($event, scope.row)"
                ></el-switch>
              </template>
            </el-table-column>
            <el-table-column min-width="180" label="操作" align="center">
              <template slot-scope="scope">
                <!-- <el-tag effect="plain" class="mr-10">查看</el-tag> -->
                <el-tag
                  v-if="scope.row.resell_status==1&&scope.row.resell_status==2"
                  effect="plain"
                  class="mr-10"
                  @click="zm(scope.row)"
                >{{scope.row.resell_status==1?'交易':scope.row.resell_status==2?'交易中':''}}</el-tag>
                <el-tag effect="plain" class="mr-10" @click="gofee(scope.row)">年费</el-tag>
                <!-- <el-tag effect="plain">票据</el-tag> -->
              </template>
            </el-table-column>
          </el-table>
          <div class="t-c mt-20" v-show="total>0">
            <el-pagination
              @current-change="changePage"
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pagesize"
              :current-page.sync="currentpage"
            ></el-pagination>
          </div>
        </el-card>
      </el-col>
      <el-col :span="7">
        <div style="height:100%" class="gd">
          <monitor></monitor>
          <infor style="flex:1"></infor>
        </div>
      </el-col>
    </el-row>
    <!-- 监控费 -->
    <div v-if="montfee" class="mont-fee">
      <div class="mont-fee-cont">
        <div class="f18 bd color-0 t-c">专利监控服务</div>
        <div class="f12" style="margin-top: 37px">您未开通监控服务（开通后您将同时获得专利【年费监控】【状态监控】功能使用权限）</div>
        <div class="flex mt-30">
          <div class="mr-30 f14 bd">监控时间</div>
          <div>
            <el-radio
              v-for="item in feeData.time"
              :key="item.id"
              :label="item.id"
              v-model="radio"
              @click.native.prevent="clickitem(item.id)"
            >{{ item.add_num }}年</el-radio>
          </div>
        </div>
        <div class="flex mt-30">
          <div class="mr-30 f14 bd">监控条数</div>
          <div>
            <el-radio
              v-for="item in feeData.row"
              :key="item.id"
              :label="item.id"
              v-model="radio1"
              @click.native.prevent="clickitem2(item.id)"
            >{{ item.add_num }}条</el-radio>
          </div>
        </div>
        <div class="flex-al mt-30">
          <span class="f14 bd color-0" style="margin-right: 15px">应付金额：</span>
          <span class="f24 bd red mr-30">{{ allsum }}元</span>
          <!-- <span class="f12">开通后有效时间：2021-05-17至2022-05-17</span> -->
        </div>
        <div class="m-0 mont-fee-btn" @click="mont()">购买</div>
        <i class="el-icon-close cancle" @click="montfee = false"></i>
      </div>
    </div>
    <!-- 交易 -->
    <el-dialog title="确认商品价格发布" :visible.sync="dialogVisible" center width="330px">
      <el-form class="mt-30" ref="form" :model="form" :rules="rul">
        <el-form-item label="交易价格" prop="price">
          <el-input v-model="form.price"></el-input>
        </el-form-item>
      </el-form>
      <div class="jbtn bg-b t-c" @click="resell">确认发布</div>
    </el-dialog>
    <!--绑定公众号 -->
    <el-dialog :visible.sync="dialogVisible1" width="290px">
      <div style="padding:10px;background: #c4c4c4; width:130px;height:130px;margin:15px auto">
        <div style="padding:10px;background: #fff;width:110px;height:110px;">
          <div ref="qrCodeUrl"></div>
        </div>
      </div>
      <div class="t-c bd red">请扫描二维码，绑定微信公众号，用于接受消息通知，这很重要！</div>
    </el-dialog>
    <!--绑定邮箱 -->
     <el-dialog top="30vh" title="绑定邮箱" :visible.sync="dialogVisible3" width="400px">
      <div>
        <div class="f12 t-c" style="color:red">请绑定邮箱,用于接受消息通知，这很重要！</div>
        <div class="t-c mt-30">
          <span class="mr-10">邮箱</span>
          <el-input v-model="email" ></el-input>
        </div>
        <div class="t-c" style="margin-top:40px">
          <span
            class="bg-b cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
            @click="dialogVisible3=false"
          >取消</span>
          <span
            @click="onSubmit1"
            class="bg-b ml-20 cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
          >提交</span>
        </div>
      </div>
    </el-dialog>
    <!-- 请完善案件必要信息 -->
    <el-dialog top="30vh" :title="pauId" :visible.sync="dialogVisible2" width="400px">
      <div>
        <div class="f12 t-c" style="color:red">请完善案件必要信息</div>
        <div v-if="ann1" class="t-c mt-30">
          <span class="mr-10">年费已缴至</span>
          <el-select v-model="annual_fee_by_time_num" placeholder="请选择">
            <el-option v-for="item in sum" :key="item" :label="item+'年'" :value="item"></el-option>
          </el-select>
        </div>
        <div v-if="ann2" class="t-c mt-30">
          <span style="width: 70px;display: inline-block;" class="mr-10">费减比例</span>
          <el-select v-model="percents" placeholder="请选择">
            <el-option label="无费减" value="无费减"></el-option>
            <el-option label="70%" value="70%"></el-option>
            <el-option label="85%" value="85%"></el-option>
          </el-select>
        </div>
        <div v-if="ann2" class="t-c mt-30">
          <span style="width: 70px;display: inline-block;" class="mr-10">费减至</span>
          <el-select v-model="years" placeholder="请选择">
            <el-option v-for="item in sum" :key="item" :label="item+'年'" :value="item"></el-option>
          </el-select>
        </div>
        <div v-if="ann2" class="t-c mt-30">
          <span class="mr-10">费减受理日</span>
          <el-date-picker type="date" v-model="cost_deduction_time" value-format="yyyy-MM-dd"></el-date-picker>
        </div>
          <div v-if="ann3" class="t-c mt-30">
          <span class="mr-10">办理登记日</span>
          <el-date-picker placeholder="办理登记通知书发文日期" type="date" v-model="check_in_time" value-format="yyyy-MM-dd"></el-date-picker>
        </div>
        <div class="t-c" style="margin-top:40px">
          <span
            class="bg-b cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
            @click="dialogVisible2=false"
          >取消</span>
          <span
            @click="onSubmit"
            class="bg-b ml-20 cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
          >提交</span>
        </div>
      </div>
    </el-dialog>
     <!--添加代理所 -->
     <el-dialog top="30vh" center :title="agcode" :visible.sync="dialogVisible4" width="400px">
      <div>
        <div class="f12 t-c">{{agname}}</div>
        <div class="t-c mt-30">
          <span class="mr-10">代理所</span>
          <el-input style="width:250px" v-model="agencyOfficeName" ></el-input>
        </div>
        <div class="t-c" style="margin-top:40px">
          <span
            class="bg-b cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
            @click="dialogVisible4=false"
          >取消</span>
          <span
            @click="setAgency"
            class="bg-b ml-20 cur t-c"
            style="display:inline-block; width: 70px;height: 32px;line-height:32px;border-radius: 4px;"
          >提交</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
var token = localStorage.getItem("token");
import monitor from "../common/monitor";
import infor from "../common/Infor";
import QRCode from "qrcodejs2";
import { setTimeout } from 'timers';
export default {
  components: {
    monitor,
    infor
  },
  created() {
    this.getList();
  },
  data() {
    var validatePrice = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入出售价格"));
      } else {
        var priceReg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if (!priceReg.test(value)) {
          callback(new Error("请输入正确的产品价格:整数或者保留两位小数"));
        } else {
          callback();
        }
      }
    };
    return {
      ann1: false,
      ann2: false,
      ann3: false,
      price_not: "",
      percents: "",
      years: "",
      patent_id: "",
      annual_fee_by_time_num: "",
      cost_deduction_time: "",
      check_in_time:"",
      pauId: "",
      pauIds: "",
      s_id: "",
      dialogVisible2: false, //年费已缴至
      pau_years_time: "",
      patent_template: "", //下载模板
      close_to_pay: "",
      is_monitoring: "",
      service_id: "",
      is_trusteeship: "0", //本所案件
      agency_office_name:'',//代理所
      patent_date_app: "",
      pay_time: "",
      patent_code: "",
      value5: "",
      name: "",
      feeData: [],
      myHeaders: { Authorization: token },
      montfee: false,
      radio1: "",
      radio: "",
      pagesize: 6,
      page: 1,
      total: 0,
      currentpage: 1,
      tableData: [],
      infor: {},
      aa: "",
      value1: "",
      dialogVisible: false, //交易
      dialogVisible1: false, //绑定公众号
      dialogVisible3:false,//邮箱
      dialogVisible4:false,//代理所
      email:"",
      lack:false,
      url:'',
      form: {
        price: "", //交易价格
        patent_id: ""
      },
      rul: {
        price: [
          {
            validator: validatePrice,
            trigger: "blur"
          }
        ]
      },
      Agid:'',
      agencyOfficeName:'',
      agcode:'',
      agname:'',
    };
  },
  methods: {
    cx() {
      this.page = 1;
      this.currentpage = 1;
      this.getList();
    },
    getList() {
      this.$request
        .get(
          `/client/annual_fee/tableData?page=${this.page}&limit=${
            this.pagesize
          }&pay_time=${this.pay_time}&service_id=${
            this.service_id
          }&patent_date_app=${this.patent_date_app}&patent_code=${
            this.patent_code
          }&is_monitoring=${this.is_monitoring}&close_to_pay=${
            this.close_to_pay
          }&is_trusteeship=${this.is_trusteeship}&agency_office_name=${this.agency_office_name}`
        )
        .then(res => {
          if (res.data.code == 200) {
            if (res.data.data.list.length > 0) {
              res.data.data.list.forEach(item => {
                if (item.annualFeeControl == null) {
                  item.annualFeeControl = {};
                  item.annualFeeControl.status = false;
                } else {
                  if (item.annualFeeControl.status == 0) {
                    item.annualFeeControl.status = false;
                  } else {
                    item.annualFeeControl.status = true;
                  }
                }
              });
            }
            this.patent_template = res.data.data.patent_template;
            this.tableData = res.data.data.list;
            this.infor = res.data.data.monitoring;
            this.total = res.data.total;
          }
        });
    },
    changePage(index) {
      this.page = index;
      this.getList();
    },
    change($event, row) {
      let id = row.id;
      let is_trusteeship = row.is_trusteeship;
      this.s_id = row.service_id;
      this.pauId = "专利号：" + row.patent_code;
      this.pauIds = row.id;
      let status = $event ? 1 : 0;
      this.$request
        .post("/client/annual_fee/setControlStatus", {
          patent_id: id,
          status,
          is_trusteeship
        })
        .then(res => {
          if(res.data.code==1000){
            this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = false;
              }
            })
             this.getList();
             this.dialogVisible3=true
             this.url=res.data.data.url
             this.lack=true
             this.email=''
          }
           if (res.data.code == 1001) {
            this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = false;
              }
            });
             this.getList();
             this.dialogVisible3=true
             this.lack=false
             this.email=''
          }
          if (res.data.code == 1002) {
            this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = false;
              }
            });
            this.wCode(res.data.data.url);
            this.getList();
          }
          if (res.data.code == 1101) {
            if (
              res.data.data.percents == 0 ||
              res.data.data.percents == "无费减" ||
              res.data.data.percents == null
            ) {
              res.data.data.percents = "";
            }
            if (
              res.data.data.percents.indexOf("%") == -1 && res.data.data.percents.indexOf("％") == -1 &&
              res.data.data.percents != ""
            ) {
              res.data.data.percents = res.data.data.percents + "%";
            }
            this.price_not = res.data.data.price_not;
            this.annual_fee_by_time_num = res.data.data.annual_fee_by_time;
            this.percents = res.data.data.percents;
            this.years = res.data.data.years == 0 ? "" : res.data.data.years;
            this.cost_deduction_time = res.data.data.cost_deduction_time;
            this.check_in_time = res.data.data.check_in_time;
            if (this.annual_fee_by_time_num) {
              this.ann1 = false;
            } else {
              this.ann1 = true;
            }
            if (this.price_not == 2 || this.price_not == 3) {
              if (this.percents && this.years && this.cost_deduction_time) {
                this.ann2 = false;
              } else {
                this.ann2 = true;
              }
            }
            if (this.price_not == 1 || this.price_not == 0) {
              this.ann2 = false;
            }
             if (!this.check_in_time) {
              this.ann3 = true;
            }else{
              this.ann3 = false
            }
            this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = false;
              }
            });
            this.dialogVisible2 = true;
            this.getList();
          }
         
          if (res.data.code == 1003) {
            this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = false;
              }
              this.getList();
            });
            this.montfee = true;
            this.getfeeData();
          }
          if (res.data.code == 200) {
            this.getList();
          }
        }).catch( (error)=> {
       this.tableData.forEach(item => {
              if (item.id == id) {
                item.annualFeeControl.status = !item.annualFeeControl.status;
              }
            });
         });
        },
    success(res) {
      this.$message({
        message: res.msg
      });
      this.getList();
    },
    error(res) {},
    gofee(row) {
      this.$router.push({
        path: "annualfee",
        query: {
          id: row.id,
          service_name: row.configService.service_name,
          name: row.name,
          patent_code: row.patent_code,
          service_id: row.service_id,
          is_trusteeship: row.is_trusteeship
        }
      });
    },
    //年费缴费至哪一年
    onSubmit() {
      if (
        this.annual_fee_by_time_num == "" ||
        this.annual_fee_by_time_num == null
      ) {
        this.$message("请选择年费已缴至第几年");
        return;
      }
      if (this.price_not == 2 || this.price_not == 3) {
        if (this.percents == "" || this.percents == null) {
          this.$message("请选择费减比例");
          return;
        }
        
        if (this.percents == "70%" || this.percents == "85%"||this.percents == "70％" || this.percents == "85％") {
          if (this.years == "" || this.years == null) {
            this.$message("请选择费减至哪一年");
            return;
          }
          if (
            this.cost_deduction_time == "" ||
            this.cost_deduction_time == null
          ) {
            this.$message("请选择费减受理日");
            return;
          }
        }
      }
        if(!this.check_in_time){
         this.$message("请选择办理登记通知书发文日期");
          return;
      }
      let data = { patent_id: this.pauIds };
      if (this.ann1) {
        data.annual_fee_by_time_num = this.annual_fee_by_time_num;
      }
      if (this.ann2) {
        data.percents = this.percents == "无费减" ? "" : this.percents;
        data.years = this.years;
        data.cost_deduction_time = this.cost_deduction_time;
      }
       if(this.ann3){
        data.check_in_time=this.check_in_time
      }
      this.$request
        .post("/client/patent/setPerfectInformation", {
          ...data,
          is_trusteeship: this.is_trusteeship
        })
        .then(res => {
          if (res.data.code == 200) {
            this.dialogVisible2 = false;
            this.getList();
          }
        });
    },
    //提交邮箱
    onSubmit1(){
       if(this.email==''){
          this.$message("请填写邮箱地址");
            return;
       }
       this.$request.post("/client/member/setEmail",{email:this.email}).then(res=>{
         if(res.data.code==200){
           this.dialogVisible3=false
           if(this.lack){
           setTimeout(()=>{
              this.wCode(this.url)
           },1500)
           }
         
         }
       })
    },
    // 监控费获取数据
    getfeeData() {
      this.$request.get("/client/monitoring/listData").then(res => {
        this.feeData = res.data.data;
        this.radio = this.feeData.time[0].id;
        this.radio1 = this.feeData.row[0].id;
      });
    },
    mont() {
      if (this.radio == "" && this.radio1 == "") {
        return this.$message("请选择监控条数或几年");
      }
      let option = { type: 4, time_id: this.radio, row_id: this.radio1 };
      this.$router.push({ path: "/confirm", query: { ...option } });
    },
    getTime(time) {
      let n = new Date(time.replace(/-/g, "/")).getTime();
      if (n - new Date().getTime() < 2592000000) {
        return true;
      } else {
        return false;
      }
    },
    clickitem(e) {
      e === this.radio ? (this.radio = "") : (this.radio = e);
    },
    clickitem2(e) {
      e === this.radio1 ? (this.radio1 = "") : (this.radio1 = e);
    },
    zm(row) {
      if (row.resell_status != "1") {
        return;
      }
      this.form.patent_id = row.id;
      this.form.price = "";
      this.dialogVisible = true;
    },
    //发布交易
    resell() {
      this.$refs.form.validate(res => {
        if (res) {
          this.$request
            .post("/client/patent/resell", { ...this.form })
            .then(res => {
              if (res.data.code == 200) {
                this.dialogVisible = false;
                this.getList();
              }
            });
        }
      });
    },
    cz() {
      this.montfee = true;
      this.getfeeData();
    },
    wCode(code) {
      this.dialogVisible1 = true;
      this.$nextTick(() => {
        this.$refs.qrCodeUrl.innerHTML = "";
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: code,
          width: 110,
          height: 110,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H
        });
      });
    },
    tapAgency(item){
        console.log(item)
        this.Agid=item.id
        this.agencyOfficeName=''
         this.agcode=item.patent_code
      this.agname=item.name
      this.dialogVisible4=true
    },
     setAgency(){
      let patent_id=this.Agid
      let agencyOfficeName=this.agencyOfficeName
      if(!agencyOfficeName){
        this.$message("请输入代理所名称")
        return
      }
      this.$request.post("/client/patent/setAgencyOfficeName",{patent_id,agencyOfficeName})
      .then(res=>{
        if(res.data.code==200){
           this.dialogVisible4=false
           this.getList()
        }
      })
    }
  },
  watch: {
    pay_time(newVal) {
      if (newVal == null) {
        this.pay_time = "";
      }
    },
    patent_date_app(newVal) {
      if (newVal == null) {
        this.patent_date_app = "";
      }
    }
  },
  computed: {
    allsum() {
      let price1 = "";
      let price2 = "";
      if (this.feeData.time && this.feeData.row) {
        this.feeData.time.forEach(item => {
          if (item.id == this.radio) {
            price1 = item.price;
          }
        });
        this.feeData.row.forEach(item => {
          if (item.id == this.radio1) {
            price2 = item.price;
          }
        });
        return Number(price1) + Number(price2);
      }
    },
    sum() {
      let snum = this.s_id == 3 ? 20 : 10;
      return snum;
    }
  }
};
</script>

<style scoped>
.mt-5 {
  margin-top: 5px;
}
.box-card {
  padding-bottom: 48px;
  flex-wrap: wrap;
}
.el-input {
  height: 32px;
  width: 170px;
}
.el-card >>> .el-input__inner {
  height: 32px;
  border-color: #bbb;
}
.el-card >>> .el-input__inner:focus {
  border-color: #2962ff;
}
.el-select {
  width: 170px;
}

.w-320 {
  width: 320px;
}

.el-tag {
  cursor: pointer;
}
.type-tag {
  display: inline-block;
  background-color: #2962ff;
  height: 20px;
  color: #fff;
  border-radius: 5px;
  padding: 0 6px;
  line-height: 20px;
  width: 60px;
  box-sizing: border-box;
  text-align: center;
}
.pay {
  width: 196px;
  height: 48px;
  background: #2962ff;
  border-radius: 26px;
  margin: 20px auto;
  color: #fff;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}

.el-card >>> .el-switch.is-checked .el-switch__core {
  border-color: #2962ff;
  background-color: #2962ff;
}
.el-tag--plain {
  border-color: #2962ff;
  color: #2962ff;
}
.el-card >>> .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2962ff;
  color: #fff;
}
.el-card >>> .el-button:focus,
.el-button:hover {
  color: #fff;
  border-color: #2962ff;
  background-color: #2962ff;
}
.mont-fee {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.mont-fee-cont {
  width: 936px;
  height: 320px;
  background: #ffffff;
  border-radius: 12px;
  margin: 180px auto;
  border: 1px solid transparent;
  padding: 30px;
  position: relative;
}
.mont-fee >>> .el-radio__input.is-checked .el-radio__inner {
  border-color: #2962ff;
  background: #2962ff;
}
.mont-fee >>> .el-radio__input.is-checked + .el-radio__label {
  color: #2962ff;
}
.el-radio {
  margin-right: 20px;
}
.mont-fee-btn {
  cursor: pointer;
  margin-top: 40px;
  font-size: 16px;
  text-align: center;
  color: #fff;
  line-height: 40px;
  width: 96px;
  height: 40px;
  background: #2962ff;
  border-radius: 4px;
}
.cancle {
  position: absolute;
  top: 24px;
  right: 30px;
  font-size: 24px;
  color: #444444;
  cursor: pointer;
}
.add {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.mont-fee
  >>> .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
  .el-radio__inner {
  box-shadow: 0 0 0px 0px #2962ff;
}
.jbtn {
  width: 100px;
  padding: 6px 0;
  border-radius: 6px;
  margin: 60px auto 0;
  cursor: pointer;
}
.we {
  display: inline-block;
  width: 80px;
  text-align: right;
}
.el-card >>> .el-input__icon {
  line-height: 33px;
}
.gd {
  display: flex;
  flex-direction: column;
}
.n-w {
  white-space: nowrap;
}
</style>